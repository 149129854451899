import LazySvg from "../../utils/LazySvg";
import { StyledAside } from "./AsideStyle";
import { menuItems } from "../../utils/menu";
import { NavLink, useLocation } from "react-router-dom";
import MenuLeft from "../../../../assets/icons/MenuLeft";
import MenuRight from "../../../../assets/icons/MenuRight";
import {  useSelector } from "react-redux";
import styled from "styled-components";
import { SettingOutlined } from "@ant-design/icons";

const Aside = ({setTheme}:any) => {
  const { toggleMenu } = useSelector((state: any) => state.appMangerUtilitySlice);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  const location = useLocation();
  const isOverview = location.pathname.startsWith("/onboarding/overview/");
  const isOverviewActive = location.pathname.includes("overview");
  const isMaintainActive = location.pathname.includes("maintain");
  const isvehicleDefectsActive = location.pathname.includes("vehicle-defects");
  const isBreakdownsActive = location.pathname.includes("breakdowns");
  const isAddVehicleActive = location.pathname.includes("add-vehicle");

  return (
    <>
      <StyledAside>
        <div className="LogoPart">
          {location.pathname === "/supplier-onboarding" ? (
            <a href="/">{toggleMenu ? <LazySvg name="logo-small" /> : <LazySvg name="supplier-logo" />}</a>
          ) : (
            <a href="/">{toggleMenu ? <LazySvg name="logo-small" /> : <LazySvg name="logo" />}</a>
          )}
        </div>
        <div className="navigation">
          <nav>
            <ul>
              {menuItems.map((item, id) => {
                const isActiveParent = location.pathname.startsWith(item.url) || item?.subMenu?.some((suMenu) => location.pathname.startsWith(suMenu.url));

                return (
                  <li key={id} className={isActiveParent ? "active" : ""}>
                    <i className="arrowIcon">
                      <LazySvg name="arrow" />
                    </i>
                    <NavLink to={item.url} className={({ isActive }) => (isActive ? "active" : "")}>
                      <div className="flex flex-item-center gap10">
                        <i className="iconWrapper flex-center">
                          <LazySvg name={item.iconName} />
                        </i>
                        <span>{item.name}</span>
                      </div>
                    </NavLink>
                    <ul>
                      {item?.subMenu?.map((suMenu, index) => (
                        <li key={index}>
                          {isOverview ? (
                            <NavLink end to={suMenu.url} className={({ isActive }) => ((isOverviewActive && suMenu.url.endsWith("overview")) || isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          ) : isMaintainActive ? (
                            <NavLink end to={suMenu.url} className={({ isActive }) => ((isMaintainActive && suMenu.url.endsWith("maintain")) || isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          ) : isvehicleDefectsActive ? (
                            <NavLink end to={suMenu.url} className={({ isActive }) => ((isvehicleDefectsActive && suMenu.url.endsWith("vehicle-defects")) || isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          ) : isBreakdownsActive ? (
                            <NavLink end to={suMenu.url} className={({ isActive }) => ((isBreakdownsActive && suMenu.url.endsWith("breakdowns")) || isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          ) : isAddVehicleActive ? (
                            <NavLink end to={suMenu.url} className={({ isActive }) => ((isAddVehicleActive && suMenu.url.endsWith("add-vehicle")) || isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          ) : (
                            <NavLink end to={suMenu.url} className={({ isActive }) => (isActive ? "active" : "")}>
                              <div className="flex flex-item-center gap10">
                                <span>{suMenu.name}</span>
                              </div>
                            </NavLink>
                          )}
                        </li>
                      ))}
                    </ul>
                    <div className="arrow">
                      <MenuLeft />
                      <MenuRight />
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        {/* <LazySvg name="vehicle" /> */}
         <Setting onClick={toggleTheme}>
            {toggleMenu ? (
              <>
         
                <SettingOutlined /> 
              </>
            ) : (
              <>
              <SettingOutlined /><p>Change Theme</p>
              </>
            )}
          </Setting>     
      </StyledAside>
    </>
  );
};


const Setting = styled.div`
  position: absolute;
  bottom: 25px;
  font-size: 16px;
  color: #fff;
  padding-left: 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;
`;
export default Aside;

import React, { useEffect, useState } from "react";
import AppRouter from "./shared/components/AppRouter";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles, lightTheme, Loader, setStore } from "@bjsdistribution/tms-component-library";
import { SettingOutlined } from "@ant-design/icons";
import { Provider, useSelector } from "react-redux";
import TmsStore from "./shared/redux/store";
// import GlobalStroe from "./shared/redux/store";


function App() {
  useEffect(() => {
    // For global loader send store to component lib on interseptor needed dispatch loader on request and response ,
    setStore(TmsStore);
  }, []);
  const [theme,setTheme] = useState("light");

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  // };
  // const { theme } = useSelector((state: any) => state.appMangerUtilitySlice);

  return (
    <>
      <Provider store={TmsStore}>
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <Loader />
          <AppRouter setTheme={setTheme} />
          <GlobalStyles />
        </ThemeProvider>
      </Provider>
    </>
  );
}
// const Setting = styled.div`
//   position: absolute;
//   bottom: 25px;
//   font-size: 16px;
//   color: #fff;
//   padding-left: 20px;
//   display: flex;
//   gap: 10px;
//   cursor: pointer;
// `;

export default App;

import { createSlice } from '@reduxjs/toolkit'


export interface UtilityType {
    toggleMenu: boolean,
    loginUserDetails:string

}

const initialState: UtilityType = {
    toggleMenu: true,
    loginUserDetails:''
}

export const appMangerUtilitySlice = createSlice({
    name: 'appMangerUtilitySlice',
    initialState,
    reducers: {

        setToggleMenu: (state, action) => {
            state.toggleMenu = action.payload
        },
        setLoginUserDetails: (state, action) => {
            state.loginUserDetails = action.payload
        },

    },
})

export const { setToggleMenu,setLoginUserDetails} = appMangerUtilitySlice.actions

export default appMangerUtilitySlice.reducer


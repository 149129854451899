// src/components/LazySvg.js

import React, { useEffect, useState } from "react";
import loadSvg from "./loadSvgs";

const LazySvg = ({ name, ...props }) => {
  const [Svg, setSvg] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchSvg = async () => {
      const loadedSvg = await loadSvg(name);
      if (isMounted) {
        setSvg(() => loadedSvg);
      }
    };

    fetchSvg();

    return () => {
      isMounted = false;
    };
  }, [name]);

  if (!Svg) return null;
  return <img src={Svg} alt={name} {...props} />;
};

export default LazySvg;

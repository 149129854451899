import React, { useEffect, useState } from "react";
import { getUrlParams } from "./shared/utils/login";
import { useApiHook } from "@bjsdistribution/tms-component-library";
import { setLoginUserDetails } from "./shared/redux/features/appMangerUtilitySlice";

const Login = ({ setIsLogin }: { setIsLogin: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const ID_TOKEN = "id_token";
  const [loading, setLoading] = useState(false);
  const hash = window.location.hash.substring(1);
  const params = getUrlParams(hash);
  const idToken = params.get(ID_TOKEN);

  const {
    data,
    fetchData,
    loading: ssoLoading,
  } = useApiHook<any>({
    endpoint: `begin_login/sso_authorization`,
    method: "GET",
    config: { headers: { Authorization: `Bearer ${idToken}` } },
  });

  useEffect(() => {
    let LoginStatus = localStorage.getItem("username");
    console.log(LoginStatus, "LoginStatusLoginStatusLoginStatus");
    if (!LoginStatus) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!ssoLoading && data?.username) {
      // @ts-ignore const username =
      localStorage.setItem("username", data?.username);
      setLoginUserDetails(data?.username);
      setIsLogin(true);
    }
  }, [ssoLoading]);

  return <></>;
};

export default Login;

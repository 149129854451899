import React from "react";
import { HeaderWrapper } from "./HeaderStyle";
import SearchIcon from "../../../../assets/icons/SearchIcon";
import Notifications from "./Notifications";
import SuperAdmin from "./SuperAdmin";
import Setting from "./Setting";
import { useDispatch, useSelector } from "react-redux";
import { setToggleMenu } from "../../redux/features/appMangerUtilitySlice";

const Header = () => {
  const dispatch = useDispatch();
  const { toggleMenu } = useSelector((state: any) => state.appMangerUtilitySlice);

  const toggleMenuFun = () => {
    dispatch(setToggleMenu(!toggleMenu));
  };
  return (
    <HeaderWrapper>
      <header id="header" className="flex-justify">
        <div className="filterData flex-item-center">
          <span className="menu" onClick={toggleMenuFun}>
            <i></i>
            <i></i>
            <i></i>
          </span>
          <div className="textBoxWrapper">
            <input type="text" placeholder="Search for anything..."></input>
            <SearchIcon />
          </div>
        </div>
        <div className="rightPart flex-item-center">
          <Notifications />
          <SuperAdmin />
          <Setting />
        </div>
      </header>
    </HeaderWrapper>
  );
};

export default Header;

import React from "react";
import NotificationIcon from "../../../../assets/icons/NotificationIcon";
import styled from "styled-components";
const Notifications = () => {
  return (
    <>
      <NotificationWrapper>
        <NotificationIcon />
        <span>5</span>
      </NotificationWrapper>
    </>
  );
};

export default Notifications;

const NotificationWrapper = styled.div`
  position: relative;
  span {
    display: block;
    position: absolute;
    min-width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.primaryRed};
    border-radius: 100px;
    color: ${({ theme }) => theme.primaryWhite};
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    top: -12px;
    right: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    cursor: pointer;
  }
`;

import React from "react";
import SettingIcon from "../../../../assets/icons/SettingIcon";
import styled from "styled-components";
const Setting = () => {
  return (
    <>
      <SettingWrapper>
        <SettingIcon />
      </SettingWrapper>
    </>
  );
};

export default Setting;

const SettingWrapper = styled.div`
  cursor: pointer;
`;

import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import Aside from "./Aside/Aside";
import Error from "./Error/Error";
import { Provider, useSelector } from "react-redux";
import Login from "../../Login";

// Lazy-load components from shared packages
const RoutingOnboardingBjs = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.RoutingOnboardingBjs,
  }))
);

const Report = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.Report,
  }))
);

const AddVehicle = lazy(() =>
  import("@bjsdistribution/tms-add-vehicle").then((module) => ({
    default: module.AddVehicle,
  }))
);

const RoutingDefectsBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingDefectsBjs,
  }))
);

const RoutingBreakdownBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingBreakdownBjs,
  }))
);

const RoutingChecksBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingChecksBjs,
  }))
);

const RoutingMaintenance = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingMaintenance,
  }))
);

const RoutingHr = lazy(() =>
  import("@bjsdistribution/tms-hr").then((module) => ({
    default: module.AddDriverComponent,
  }))
);

function AppRouter({setTheme}:any) {
  const { toggleMenu } = useSelector((state: any) => state.appMangerUtilitySlice);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  console.log(isLogin, "AppRouter isLogin...");
  useEffect(() => {
    const username = localStorage.getItem("username");
    console.log(username, "usernameusernameusernameusernameusername");
    if (username) {
      setIsLogin(true);
    } else {
      console.log("Need to redirect login");
    }
  }, [isLogin]);

  const LoadingMessage = () => <div>Loading...</div>;

  return (
    <Router>
      <Login setIsLogin={setIsLogin} />
      {isLogin && (
        <MainContainer className={toggleMenu ? "activeMenu" : ""}>
          <Aside setTheme={setTheme}/>
          <div className="dashboard">
            <Header />
            <div className="placeHolder">
              <Suspense fallback={<LoadingMessage />}>
                <Routes>
                  <Route path="/" element={<Navigate to="/onboarding/overview" replace />} />
                  <Route path="/onboarding" element={<Navigate to="/onboarding/overview" replace />} />
                  <Route path="/onboarding/*" element={<RoutingOnboardingBjs />} />
                  <Route path="/onboarding/report" element={<Report />} />
                  <Route path="/add-vehicle/*" element={<AddVehicle />} />
                  <Route path="/maintenance" element={<Navigate to="/maintenance/maintenance" replace />} />
                  <Route path="/maintenance/*" element={<RoutingMaintenance />} />
                  <Route path="/maintenance/vehicle-defects/*" element={<RoutingDefectsBjs />} />
                  <Route path="/maintenance/vehicle-checks/*" element={<RoutingChecksBjs />} />
                  <Route path="/maintenance/breakdowns/*" element={<RoutingBreakdownBjs />} />

                  {/* TMS HR */}
                  <Route path="/hr" element={<Navigate to="/hr/overview" replace />} />
                  <Route path="/hr/*" element={<RoutingHr />} />

                  <Route path="*" element={<Error />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </MainContainer>
      )}
    </Router>
  );
}

const MainContainer = styled.div`
  display: flex;
  .dashboard {
    flex: 1;
    background: #eaedf7;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 25px 20px 10px 20px;
    }
  }
`;

export default AppRouter;
